import React, { useEffect } from 'react';

const TradingViewWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: 600,
      height: 400,
      currencies: [
        'EUR',
        'USD',
        'JPY',
        'GBP',
        'CHF',
        'AUD',
        'CAD',
        'NZD',
      ],
      isTransparent: false,
      colorTheme: 'dark',
      locale: 'en',
      backgroundColor: '#000000',
    });
    document.getElementById('tradingview-widget-container').appendChild(script);
  }, []);

  return (
    <div className="grid grid-cols-6 gap-6 p-6">
        {/* Forex Paragraph Section (Right Side) */}
        <div className="col-span-6 lg:col-span-3 lg:w-full md:w-full w-full">
        <h2 className="font-semibold text-[#dcc119] uppercase text-2xl md:text-3xl">
          Forex
        </h2>
        <p className="mt-4 text-gray-200 text-base text-justify">
          Stay ahead in the dynamic world of foreign exchange with Black Bot's real-time forex trading capabilities. Our platform provides you with up-to-the-minute market data and powerful tools to maximize your trading potential.
        </p>
        <p className="mt-4 text-gray-200 text-base text-justify">
          Experience the volatility and opportunity in major currency pairs like GBP/USD, which has seen a significant 0.5% increase, or EUR/JPY with its notable 0.22% rise. Whether you're interested in major pairs or exotic currencies, our comprehensive forex offerings cater to all trading strategies.
        </p>
        <p className="mt-4 text-gray-200 text-base text-justify">
          With Black Bot, you'll have access to a wide range of currency pairs, competitive spreads, and advanced charting tools to help you make informed trading decisions in the fast-paced forex market.
        </p>
        <div className="mt-12">
          <a
            href="#"
            className="uppercase hover:text-gray-900 font-medium bg-[#dcc119] hover:bg-[#d3c566] text-sm px-4 rounded-md hover:rounded-full transition duration-700 ease-in-out text-white py-2"
          >
            Learn More
          </a>
        </div>
      </div>
      {/* TradingView Widget Section (Left Side) */}
      <div className="col-span-6 lg:col-span-3">
        <div className="tradingview-widget-container" id="tradingview-widget-container">
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a
              href="https://www.tradingview.com/"
              rel="noopener nofollow"
              target="_blank"
            >
              <span className="blue-text">Track all markets on TradingView</span>
            </a>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default TradingViewWidget;
